<template>
    <section>
        <div class="d-flex mx-0">
            <div class="col-7 pl-0 custom-scroll overflow-auto" style="height:calc(100vh - 190px);">
                <div class="row mx-0 align-items-center bg-white br-8 py-3 px-3">
                    <p class="col px-0 f-22 f-500">
                        <span class="text-general">
                            {{ delivery.nombre }}
                        </span>
                    </p>
                    <div class="col-auto px-0 ml-auto d-flex">
                        <span v-if="delivery.calificacion" class="f-500 text-general"> {{ agregarSeparadoresNumero(delivery.calificacion.calificacion_general,1)}} </span>
                        <span v-else class="f-500 text-general"> 0 </span>
                        <i class="icon-star f-18 text-warning cr-pointer" @click="modalDetalleCalificaciones()" />
                        <div class="btn-action border">
                            <i class="icon-pencil-outline f-17" @click="editarDelivery" />
                        </div>
                    </div>
                    <div class="col-12 px-0 d-flex pl-4 mt-3">
                        <div class="col-auto px-0">
                            <img :src="delivery.imagen" width="162" height="162" class="obj-cover border-gris br-5" />
                        </div>
                        <div class="col text-general f-14">
                            <!-- Contacto -->
                            <p class="f-17 text-general f-600 mb-3">
                                Contacto
                            </p>
                            <div class="row mx-0 align-items-center text-general mb-2">
                                <div class="col-auto pl-0 text-general f-600">
                                    Número de telefono:
                                </div>
                                <div class="col-auto">
                                    {{ delivery.telefono }}
                                </div>
                            </div>
                            <div class="row mx-0 text-general mb-2">
                                <div class="col-auto text-general f-600 px-0">
                                    Dirección de correo electrónico:
                                </div>
                                <div class="col-auto">
                                    {{ delivery.correo }}
                                </div>
                            </div>
                            <!-- Contacto -->

                            <!-- Fechas -->
                            <p class="text-general mb-2">
                                <b>
                                    Fecha de nacimiento:
                                </b>
                                {{ formatearFecha(delivery.fecha_nacimiento) }}
                            </p>
                            <p class="text-general my-1">
                                <b>
                                    Fecha de vinculacion:
                                </b>
                                {{ formatearFecha(delivery.fecha_vinculacion) }}
                            </p>
                            <!-- Fechas -->
                        </div>
                    </div>
                </div>
                <!-- Checks -->
                <div class="row mx-0 my-3 br-8 bg-white py-3">
                    <p class="col-12 px-4 text-black f-500 f-16 text-general">
                        Permisos
                    </p>
                    <div class="col-12 px-4 d-middle mt-2 f-14">
                        <el-checkbox v-model="delivery.permiso_finalizar" class="check-red" @change="updateFinalizarPedido" />
                        <div class="col-auto text-general f-400">
                            Permitido finalizar pedido con novedad
                        </div>
                        <el-tooltip effect="light" placement="bottom" content="Los pedidos que haya reportado este delivery con una novedad, el mismo podrá finalizarlos">
                            <i class="icon-info-circled-alt f-18 text-general2" />
                        </el-tooltip>
                    </div>
                    <div class="col-12 px-4 d-middle mx-0 my-2 f-14">
                        <el-checkbox v-model="delivery.permiso_comprar" class="check-red" @change="updateComprar" />
                        <div class="col-auto text-general f-400">
                            Permitido acceder a los productos en gestión
                        </div>
                        <el-tooltip effect="light" placement="bottom" content="El delivery podrá gestionar los productos desde la versión móvil.">
                            <i class="icon-info-circled-alt f-18 text-general2" />
                        </el-tooltip>
                    </div>
                </div>
                <!-- Checks -->
                <div class="row mx-0 bg-white py-3 br-8 pl-4">
                    <p class="col-12 px-0 text-general f-500 f-17 mb-3">
                        Cedis vinculados
                    </p>
                    <div class="col-12 px-0 text-general">
                        <div v-for="(cedi, c) in delivery.cedis" :key="c" class="row align-items-center mx-0">
                            <div class="text-center" style="width:30px;">
                                <i v-if="cedi.principal" class="icon-warehouse f-22" />
                            </div>
                            <div class="col px-0">
                                <p>{{ cedi.nombre }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Licencia -->
                <div class="row mx-0 py-3 mt-3 bg-white br-8 pl-4">
                    <p class="f-17 text-general f-600">
                        Licencia
                    </p>
                    <div class="col-12 pl-0 mb-2">
                        <div class="row mx-0 align-items-center">
                            <div class="col-auto pl-0 text-general f-600">
                                Tipo de vehiculo:
                            </div>
                            <i v-if="delivery.aplica_moto" class="icon-moped text-general2 f-25" />
                            <i v-if="delivery.aplica_carro" class="icon-car text-general2 f-25" />
                            <i v-if="delivery.aplica_camioneta" class="icon-van text-general2 f-25" />
                            <i v-if="delivery.aplica_camion" class="icon-truck-outline text-general2 f-25" />
                        </div>
                    </div>
                    <div class="col-12 pl-0 mb-2">
                        <div class="row mx-0 text-general ">
                            <div class="col-auto text-general f-600 px-0">
                                Vigencia permiso de conducción:
                            </div>
                            <div class="col-auto">
                                {{ formatearFecha(delivery.fecha_conducir) }}
                            </div>
                            <div class="col-auto">
                                Vence {{ delivery.vigencia }}
                            </div>
                        </div>
                    </div>
                    <div class="col-12 px-0 my-3">
                        <div class="row mx-0">
                            <div class="col-5 mx-1 px-0">
                                <p class="text-general f-600 text-center">
                                    Frontal
                                </p>
                                <img :src="delivery.conducir_documento_frente_firmada" width="100%" height="162" class="obj-cover border br-5" />
                            </div>
                            <div class="col-5 mx-1 px-0 ml-2">
                                <p class="text-general f-600 text-center">
                                    Posterior
                                </p>
                                <img :src="delivery.conducir_documento_atras_firmada" width="100%" height="162" class="obj-cover border br-5" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Licencia -->
                <!-- Entregas realizadas y programadas  -->
                <div class="row mx-0 bg-white py-3 br-8 pl-4 mt-3">
                    <p class="col-12 px-0 text-general f-18 f-500 my-3">
                        Entregas realizadas y programadas
                    </p>
                    <div class="col-12 px-0">
                        <div class="row mx-0 my-2">
                            <div class="col-4">
                                <div class="bg-light-f5 text-general f-16 shadow px-4 py-3 text-center br-10">
                                    Última semana <b>{{ agregarSeparadoresNumero(entregas.ultima_semana) }}</b>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="bg-light-f5 text-general f-16 shadow px-4 py-3 text-center br-10">
                                    Hoy <b>{{ agregarSeparadoresNumero(entregas.hoy) }}</b>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="bg-light-f5 text-general f-16 shadow px-4 py-3 text-center br-10">
                                    Mañana <b>{{ agregarSeparadoresNumero(entregas.maniana) }}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 px-0">
                        <!-- Entregas Cedis -->
                        <entrega-cedis :cedis-entregas="cedisEntregas" />
                        <!-- Entregas Cedis -->
                    </div>
                </div>
                <!-- Entregas realizadas y programadas  -->
                <!-- Entregas y dinero en 2 semanas -->
                <div class="row mx-0 bg-white py-3 pl-4 mt-3 br-8">
                    <p class="col-12 px-0 text-general f-16 f-600 my-3">
                        Entregas y dinero en las 2 semanas (por entregas)
                    </p>
                    <!-- Entregas y dinero en 2 semanas -->
                    <echart :options="entregas_dinero" />
                </div>
            </div>
            <div class="col bg-white br-t-8 ml-3 pr-0">
                <div class="row mx-0 my-3">
                    <p class="text-general f-18 f-500 px-0 my-3">
                        Historial del perfil
                    </p>
                    <template v-if="$can('boton_delivery_cambiar_estado')">
                        <div v-if="delivery.estado == 1" class="col-auto px-0 my-3 ml-auto mx-3">
                            <div class="bg-general cr-pointer text-white shadow br-10 px-3 py-1" @click="inactivarDelivery()">
                                Inactivar
                            </div>
                        </div>
                        <div v-else class="col-auto px-0 my-3 ml-auto mx-3">
                            <div class="bg-general cr-pointer text-white shadow br-10 px-3 py-1" @click="activarDelivery()">
                                Activar
                            </div>
                        </div>
                    </template>
                </div>
                <div class="custom-scroll overflow-auto" style="height:calc(100vh - 290px);">
                    <div class="row mx-0 pr-2">
                        <div v-for="(estado, e) in delivery.estados" :key="e" class="col-12 bg-light-f5 br-10 text-general my-2 py-2">
                            <p class="text-general">
                                <span class="f-500">{{ estado.titulo }}: </span> {{ formatearFecha(estado.created_at) }}
                            </p>
                            <p class="text-general2">
                                {{ estado.justificacion }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-editar-delivery ref="modalEditarDelivery" @actualizar="getDelivery" />
        <modal-detalle-calificaciones ref="modalDetalleCalificaciones" />

        <modal ref="modalActivarDelivery" titulo="Activar Delivery" icon="delivery" no-aceptar adicional="Activar" @adicional="cambiarEstado(1)">
            <ValidationObserver ref="validacion">
                <p class="text-general f-16 text-center my-3">
                    ¿Desea activar a este delivery?
                </p>
                <div class="row mx-0 justify-center">
                    <div class="col-10">
                        <p class="text-general f-15 pl-3">Justificación</p>
                        <ValidationProvider v-slot="{ errors }" rules="required|max:300" vid="justificación" name="justificación">
                            <el-input v-model="justificacion" placeholder="Justificación..." type="textarea" :rows="4" maxlength="300" show-word-limit />
                            <span class="text-danger f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <modal ref="modalInactivarDelivery" titulo="Inactivar delivery" icon="delivery" no-aceptar adicional="Inactivar" @adicional="cambiarEstado(0)">
            <ValidationObserver ref="validacion">
                <p class="text-general f-16 text-center my-3">
                    ¿Desea inactivar a este delivery?
                </p>
                <div class="row mx-0 justify-center">
                    <div class="col-10">
                        <p class="text-general f-15 pl-3">Justificación</p>
                        <ValidationProvider v-slot="{ errors }" rules="required|max:300" vid="justificación" name="justificación">
                            <el-input v-model="justificacion" placeholder="Justificación..." type="textarea" :rows="4" maxlength="300" show-word-limit />
                            <span class="text-danger f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
    </section>
</template>

<script>
import Vue from 'vue'
import echarts from 'echarts'
import { mapGetters } from 'vuex'
import Delivery from "~/services/delivery/index";
Vue.component('echarts',echarts);
let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-db')
let color = [ '#A78FE2', '#F1BC8D', '#72DCCF', '#F1E1AB', '#8592A4', '#71C1EC', '#D382C7', '#79BB88', '#9FDDF8', '#F171D8', '#7797F1', '#DC7279', '#B78471', '#76A0B8', '#87DC72' ];

export default {
    components: {
        entregaCedis: () => import('./components/entregasCedis'),
        modalEditarDelivery: () => import ('../partials/modalEditarDelivery'),
        modalDetalleCalificaciones: () => import ('./partials/modalDetalleCalificaciones')
    },
    data(){
        let self = this
        return {
            url_image: 'https://www.ashoka.org/sites/default/files/styles/medium_1600x1000/public/thumbnails/images/daniela-kreimer.jpg?itok=R89tVtb4',
            entregas_dinero: {
                legend: {
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 14,
                        color: '#707070',
                        padding: [3, 8, 0, 2]
                    },
                    data:['Entrega', 'Valor'],
                },
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].name}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[0].data.cantidad)}</span>`  + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2">${self.separadorNumero(obj[1].data.total)}</span>` + '<br>'
                        + '</div>'
                    }
                },
                xAxis: [
                    {
                        // name: 'Valor',
                        type: 'category',
                        scale: true,
                        axisLabel: {
                            color: '#6D6B6B',
                            interval: 1
                        }
                    },
                ],
                dataset:{
                    dimensions: ['dia','cantidad','total'],
                    source: []
                },
                yAxis: [
                    {
                        type: 'value',
                        boundaryGap: true,
                    },
                    {
                        type: 'value',
                        boundaryGap: true,
                        show:false
                    }
                ],
                series: [
                    {
                        type: "bar",
                        name: "Entrega",
                        barWidth: "20",
                        itemStyle: {
                            normal: {
                                color: colorGeneral2
                            }
                        }
                    },
                    {
                        type: "line",
                        name: "Valor",
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral,
                        },
                        symbolSize: 10,
                        symbol: 'circle',
                    }
                ]
            },
            checked: false,
            justificacion: '',
            delivery: {
                estado: null,
                updated_at: null,
                nombre: null,
                foto: null,
                id_cedis: null,
                tipo: null,
                idUser: null,
                multicedis: null,
                id: null,
                fecha_nacimiento: null,
                fecha_vinculacion: null,
                fecha_conducir: null,
                conducir_documento_frente: null,
                conducir_documento_atras: null,
                permiso_finalizar: null,
                permiso_comprar: null,
                aplica_moto: null,
                aplica_carro: null,
                aplica_camioneta: null,
                aplica_camion: null,
                documento_identidad: null,
                vigencia: null,
                id_user: null,
                created_by: null,
                updated_by: null,
                created_at: null,
                imagen: null,
                conducir_documento_frente_firmada: null,
                conducir_documento_atras_firmada: null,
                estados: [],
                cedis: [],
                ids_cedis: [],
                calificacion:{}
            },
            entregas: {
                ultima_semana: 0,
                hoy: 0,
                maniana: 0,
            },
            cedisEntregas: [],
            entregasFechas: [],
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
        }),
        idDelivery(){
            return this.$route.params.id_delivery
        }
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_moneda === null){
                    setTimeout(async() => {
                        await this.getDelivery()
                    }, 1000);
                }else{
                    await this.getDelivery()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        activarDelivery(){
            this.$refs.modalActivarDelivery.toggle();
        },
        async getDelivery(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                }
                const { data } = await Delivery.getDelivery(this.idDelivery, params)

                this.delivery = data.delivery
                this.entregas = data.entregas
                this.entregasFechas = data.entregasFechas
                this.entregas_dinero.dataset.source = data.entregasFechas

                let cedisEntregasLocal = []
                let cedisEntregas = data.cedisEntregas
                for (var i = 0; i < cedisEntregas.length; i++){
                    cedisEntregasLocal.push({
                        id: cedisEntregas[i].id,
                        nombre: cedisEntregas[i].nombre,
                        cantidad: this.agregarSeparadoresNumero(cedisEntregas[i].cantidad),
                        dinero: this.separadorNumero(cedisEntregas[i].dinero),
                        porcentaje_cantidad: this.agregarSeparadoresNumero(cedisEntregas[i].porcentaje_cantidad, 1),
                        porcentaje_dinero: this.agregarSeparadoresNumero(cedisEntregas[i].porcentaje_dinero, 1),
                        porcentaje_max_cantidad: this.agregarSeparadoresNumero(cedisEntregas[i].porcentaje_max_cantidad, 1),
                        porcentaje_max_dinero: this.agregarSeparadoresNumero(cedisEntregas[i].porcentaje_max_dinero, 1),
                        color: color[i],
                    });
                }

                this.cedisEntregas = cedisEntregasLocal
            } catch (error){
                this.error_catch(error)
            }
        },
        inactivarDelivery(){
            this.$refs.modalInactivarDelivery.toggle()
        },
        editarDelivery(){
            let model = {...this.delivery}
            model.password = '*********'
            model.password_confirmation = '*********'
            this.$refs.modalEditarDelivery.toggle(model);
        },
        async updateFinalizarPedido(){
            try {
                const {data} = await Delivery.updateFinalizarPedido(this.delivery.id)
                this.notificacion('Mensaje', 'Informacion actualizada', 'success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async updateComprar(){
            try {
                const {data} = await Delivery.updateComprar(this.delivery.id)
                this.notificacion('Mensaje', 'Informacion actualizada', 'success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async cambiarEstado(estado){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    return false
                }
                let params = {
                    id: this.idDelivery,
                    estado,
                    justificacion: this.justificacion
                }
                const {data} = await Delivery.cambiarEstado(params)
                let msg = estado ? 'Activado correctamente' : 'Inactivado correctamente'
                this.notificacion('Mensaje', msg, 'success')
                if (estado){
                    this.$refs.modalActivarDelivery.toggle()
                }else{
                    this.$refs.modalInactivarDelivery.toggle()
                }
                this.delivery.estado = estado
                data.model.titulo = estado ? 'Activado' : 'Inactivado'
                this.delivery.estados.unshift(data.model)
            } catch (e){
                this.error_catch(e)
            }
        },
        modalDetalleCalificaciones(){
            this.$refs.modalDetalleCalificaciones.toggle(this.delivery.calificacion)
        }
    }

}
</script>
